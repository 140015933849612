$bp--ipad: (min-width 1025px);

.site-container {
  .checkout__content {
    .registration-panel,
    .sign-in-confirmation-panel {
      .checkout__panel-content {
        .password {
          input {
            width: 50%;
          }
        }
      }
      .checkout-registration {
        .checkout-registration__email-address {
          margin-top: 10px;
        }
      }
    }
  }
  .viewcart {
    .right {
      &.checkout__sidebar {
        .samples-panel {
          .offer h3 {
            font-size: 24px;
          }
        }
      }
    }
    .donation_amounts {
      .donation_amount {
        display: inline-block;
      }
    }
    .checkout__content {
      #guarantee-panel {
        border-bottom: none;
      }
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
      #top-viewcart-buttons {
        display: none;
      }
      #bottom-viewcart-buttons {
        .continue-buttons {
          padding-right: 8.35%;
          text-align: right !important;
        }
      }
    }
    #top-viewcart-buttons {
      border-bottom: 1px solid $color--gray--lightest;
      margin-left: 1em;
      .viewcart-buttons-panel {
        .continue-checkout {
          margin-top: 10px;
        }
      }
    }
    .viewcart-panel {
      .viewcart-panel__content {
        .cart-header {
          padding: 1em 0 2em 0;
        }
      }
    }
  }
  .checkout__index-content {
    .shipping-edit-content {
      form#checkout_shipping_panel {
        .default-shipping {
          @include input_checkbox;
        }
        .default-billing {
          @include input_checkbox;
        }
        .email-and-sms-promotions {
          .pc_email_promo_container {
            @include input_checkbox;
          }
        }
        .sub-section {
          .address-form {
            select {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .checkout__content {
      #registration-panel {
        #registration-panel-edit {
          border-bottom: none;
        }
      }
      #payment-panel {
        .security-info {
          display: none;
        }
        .payment-form__method {
          .form-item.cvv {
            input {
              width: 18.2em;
            }
            .security-code-overlay {
              margin: 6px 0 0 2px;
              display: block;
            }
          }
          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin-bottom: 0;
          }
          .card_type_field {
            .select2-choice {
              padding-left: 8px;
            }
          }
        }
        .continue-button-wrapper {
          .submit {
            .place-order {
              padding: 0;
              width: 228px;
            }
          }
        }
      }
    }
  }
}

.ship-type {
  .picker-radio {
    float: left;
  }
}

.panel {
  .valid_marker,
  .invalid_marker {
    display: none !important;
    font-size: 0em;
  }
  .colony-and-other {
    .valid_marker {
      right: 10px;
    }
  }
  .postal-code {
    .valid_marker {
      right: 0px;
    }
  }
  .payment-container {
    .sub-section {
      clear: both;
    }
  }
}

.signin-panel {
  .js-forgot-pw-note {
    margin-bottom: 15px;
  }
}

#confirmation-panel {
  .confirmation-panel__print-buttons {
    margin-bottom: 10px;
  }
}

#mobile-breadcrumb {
  display: none;
}

.invoice_container {
  position: relative;
  padding: 20px 20px 0 10px;
  .menu-item-blocks {
    display: none;
  }
  .messages.error {
    background-color: #ffffff;
    color: #b84947;
    border-bottom: 0px;
  }
  .invoice_wrapper {
    float: left;
    width: 50%;
    margin-bottom: 20px;
  }
  .form-container {
    .form-item {
      margin: 0.5em 0.5em 0.5em 0;
      display: inline-block;
      width: 48%;
    }
  }
}

.checkout {
  .right {
    .guarantee-panel {
      display: none;
    }
    &.checkout__sidebar {
      #favorites-panel {
        .favorites-panel__title {
          margin-top: 0px;
        }
      }
    }
  }
  .messages-container {
    #error_account_lockout {
      background-color: #f14a29;
      color: #fff;
    }
  }
  .sign-in-panel {
    border-bottom: 1px solid #dbdbdb;
  }
  .checkout__content {
    padding-left: 0;
    #guarantee-panel {
      border-bottom: none;
    }
  }
  #order-summary-panel {
    .order-summary__shipping-content {
      #checkout_shipmethod {
        label {
          display: inline-block !important;
        }
      }
    }
  }
  #viewcart-panel {
    .cart-item__price {
      .cart-item {
        &__product-price--non-sale {
          text-decoration: line-through;
        }
        &__product-price--sale {
          color: #f00;
        }
      }
    }
  }
}

.checkout {
  .site-header__suffix {
    .site-my-shades-v1 {
      display: none;
    }
  }
}

.site-container {
  .checkout__content {
    .registration-panel {
      .checkout-registration {
        .checkout-registration__email-address {
          display: block;
          .example-user-email {
            display: inline;
          }
        }
      }
    }
  }
}

#confirmation-page {
  .checkout-header {
    .checkout-header__title {
      right: 0;
    }
  }
}

.field-container {
  &.city-entered {
    input {
      &.error {
        border: 1px solid $color--red;
        box-shadow: none;
      }
    }
  }
}

.enhanced-cart-page {
  .checkout.viewcart {
    #promo-panel {
      margin-top: 20px;
    }
    #recommended-products-panel {
      .recommended-item {
        .info {
          height: 8.5em;
        }
        .formatted_price {
          position: absolute;
          bottom: 0;
        }
      }
    }
    #cart-items-panel {
      .accordionPanel.past-purchase-section {
        margin-bottom: 20px;
      }
    }
    .favorites-panel {
      &__favorites-message--signedin {
        margin: 15px 0 0;
      }
    }
    #offer-code-panel {
      .offer-code__byline {
        a {
          cursor: pointer;
        }
      }
    }
  }
}
