.section-stores {
  .store-locator-results {
    .store-locator-landing__form {
      .form--search--advanced {
        width: 55%;
      }
    }
  }
}

input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

#prechat_container {
  .prechat-form-body {
    .prechat-messaging-area {
      letter-spacing: 1px;
    }
    .liveperson-btn-container {
      margin-top: 12px;
    }
    .msg-wrapper.clearfix {
      padding-bottom: 15px;
    }
  }
}

.section-stores {
  .locations_map_panel {
    .gmnoprint {
      div[title^='Obtiene una vista panorámica hacia la izquierda'],
      div[title^='Obtiene una vista panorámica hacia la derecha'],
      div[title^='Obtiene una vista panorámica hacia arriba'],
      div[title^='Obtiene una vista panorámica hacia abajo'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
}

.site-container {
  .artistry-video-player__products {
    .video-player__product-slide {
      .product__footer {
        .product__add-to-bag {
          padding: 0 10px;
        }
      }
    }
  }
  .viewcart-panel {
    &__content {
      .cart-item__qty {
        select {
          @include breakpoint($width-large - 1) {
            border-color: transparent;
          }
          @include breakpoint($width-xlarge) {
            border-color: #000;
          }
        }
      }
    }
  }
  .content {
    .responsive-container {
      .sec-nav__title {
        width: auto;
        padding-#{$rdirection}: 0;
      }
    }
  }
  .contact-us-page {
    .email-artist-title {
      font-family: $ano-bold_regular-font;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 20px;
      float: left;
      width: 22.5%;
      margin-right: 2%;
    }
    .contact-nav {
      li {
        min-height: 283px;
        &.js-email-artist {
          border: none;
        }
      }
    }
  }
  #product-page-livechat-hitarea {
    width: 122px;
  }
  .artistry-artists-page__content {
    .artistry-artists__pager {
      padding: 35px 0 35px;
    }
  }
}

#custom-container {
  #user-input-container,
  .text-input-container {
    width: 100% !important;
  }
  div.input-field-container {
    width: 75% !important;
  }
  input.chat-input-field {
    width: 100% !important;
  }
}

.waitlist-iframe-wrapper {
  width: 95%;
  height: 190px;
  margin: 17px 7px 5px 7px;
}

.tiny-waitlist-overlay {
  .waitlist_header {
    font-size: 20px;
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
  }
}

.field {
  &.clearfix {
    display: flex;
  }
  .email_input {
    float: left;
    width: 79%;
  }
  .align-r {
    float: left;
    margin-left: 10px;
    .input-btn {
      height: auto;
      margin-top: 0;
    }
  }
  .terms_condition_cont {
    float: right;
    margin: 10px 5px 0 0;
  }
}

.site-footer--top {
  .grid-container--6 .grid--item {
    &:nth-of-type(6n + 2) {
      width: 19.66667%;
      margin-left: 19.66667%;
    }
    &:nth-of-type(6n + 3) {
      width: 19.66667%;
      margin-left: 39.33333%;
    }
    &:nth-of-type(6n + 4) {
      width: 19.66667%;
      margin-left: 59%;
    }
    &:nth-of-type(6n + 5) {
      width: 19.66667%;
      margin-left: 78.66667%;
    }
  }
}

.page-product-productos {
  .site-container {
    .product__footer {
      a.notify_me {
        @include notifymebtn;
        padding: 18px 0;
        width: 100%;
        position: static;
        font-size: 1.5em;
      }
      a.notify_me:hover {
        text-decoration: underline;
      }
    }
  }
}

.site-header {
  li.site-navigation__item {
    &.is-dropdown {
      .site-navigation__dropdown .site-navigation__submenu-col {
        @include breakpoint($bp--xlarge-up) {
          min-width: 150px;
        }
      }
    }
  }
}

.prod_inv_status-2 {
  .product__footer {
    .product__inventory-status {
      .temp-out-of-stock {
        display: block;
      }
    }
  }
}

.grid--mpp__item {
  .product--teaser {
    .product__footer {
      display: flex;
      flex-direction: column;
      height: 17%;
      .product-item__add-to-cart {
        display: flex;
        flex-direction: column;
        align-items: end;
      }
      a.notify_me {
        margin: 4px 0;
      }
      .product-item__out-of-stock {
        height: auto;
        top: 12px;
      }
      .product__inventory-status {
        a.notify_me {
          margin: 0;
        }
      }
    }
  }
}

.page--spp__product {
  .product__detail {
    .product__footer {
      .product__inventory-status {
        .notify_me {
          display: none;
        }
      }
      .product__inventory-status {
        > li {
          text-align: center;
        }
      }
    }
  }
}

.page-product-productos {
  .site-container {
    .product__footer {
      a.notify_me {
        @include notifymebtn;
        padding: 18px 0;
        width: 100%;
        position: static;
        font-size: 1.5em;
      }
      a.notify_me:hover {
        text-decoration: underline;
      }
    }
  }
}

.favorites-page {
  .favorites-board__list {
    .pyramid-grid {
      .favorites-board__item {
        .product__footer {
          a.notify_me {
            display: none;
          }
          .product-item__out-of-stock {
            text-align: right;
          }
          .product-item__unavailable-message {
            float: none;
            line-height: 1.5;
            padding-left: 0;
            text-align: right;
          }
        }
      }
    }
    .grid--mpp__item {
      .product__detail {
        .product__footer {
          .product-item__out-of-stock {
            text-align: right;
          }
          a.notify_me {
            display: none;
            margin: 0;
          }
        }
      }
    }
  }
}

.account-page__content,
.past-purchases-page {
  .grid--mpp {
    .product--teaser {
      .product-item__details {
        .product__footer {
          .product-item__add-to-cart {
            p {
              margin-top: 20px;
              @include breakpoint($bp--large-down) {
                margin-top: 0;
                padding-bottom: 4px;
              }
            }
            .product-item__unavailable-message {
              float: right;
              line-height: 0;
            }
            .notify-me {
              display: none;
            }
          }
        }
      }
    }
  }
}

.past-purchases-page {
  .grid--mpp {
    .product--teaser {
      .product-item__details {
        .product__footer {
          .product-item__add-to-cart {
            .notify-me {
              display: block;
            }
          }
        }
      }
    }
  }
}

.section-esearch {
  .search-results {
    &__filters {
      .results-header {
        margin: 0;
        border-top: 0;
      }
    }
    .search-results__products {
      .search-results__results {
        padding-top: 0px;
      }
    }
  }
}

.select2-drop {
  .select2-search {
    display: none;
  }
}

.section-employment {
  .customer-service__page {
    .customer-service__page-content {
      .basic-responsive-v1 {
        @include breakpoint($bp--medium-up) {
          max-width: 100%;
        }
        @include breakpoint($width-large) {
          padding-right: 27%;
        }
      }
    }
  }
}

div#toolbar {
  z-index: 99999;
}

.checkout__content {
  .panel {
    input,
    select {
      &:focus {
        border-color: $color--black;
      }
    }
  }
}

.grid--mpp__item {
  .product--teaser {
    &.product--shaded {
      &.prod_inv_status-2 {
        .product__footer {
          .product__inventory-status {
            li.temp-out-of-stock {
              margin-top: 40px;
              position: absolute;
              right: 0px;
            }
          }
        }
        &.product--orderable {
          &.product--shoppable {
            .product__footer {
              .product__inventory-status {
                li.temp-out-of-stock {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    &.product--orderable {
      &.product--shoppable {
        &.prod_inv_status-2 {
          .product__footer {
            .hide-non-shoppable-product {
              display: none;
            }
            .product__inventory-status {
              li.temp-out-of-stock {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.checkout {
  .viewcart-panel {
    .messages-container {
      .single-message {
        border-bottom: 0px;
        background-color: transparent;
      }
    }
  }
}

.section-shipping {
  .customer-service__page {
    .customer-service__page-content {
      .basic-responsive-v1 {
        max-width: 850px;
      }
    }
  }
}

.section-returns {
  .customer-service__page {
    .customer-service__page-content {
      .basic-responsive-v1 {
        max-width: 850px;
      }
    }
  }
}

.section-mac-pro {
  .basic-responsive-v1 {
    max-width: 850px;
  }
}

.section-privacy-policy {
  .basic-responsive-v1 {
    max-width: 850px;
  }
}

.section-terms-conditions {
  .basic-responsive-v1 {
    max-width: 850px;
  }
}

.section-supplier-relations {
  .basic-responsive-v1 {
    max-width: 850px;
  }
}

.section-counterfeit-education {
  .basic-responsive-v1 {
    max-width: 850px;
  }
}

.section-macselect-terms-conditions {
  .basic-responsive-v1 {
    max-width: 850px;
  }
}

#cboxClose {
  right: 18px;
}

.submenu__header {
  @include breakpoint($width-large - 1 $width-xlarge + 1) {
    width: auto;
  }
}

.site-container {
  .contact-us-page {
    .contact-nav {
      margin-bottom: 30px;
      li,
      li .details {
        @include breakpoint($bp--large-up - 1) {
          min-height: 230px;
          border-bottom: 0px;
        }
        @include breakpoint($bp--xlarge-up) {
          min-height: 230px;
        }
      }
      div.details {
        @include breakpoint($bp--large-up - 1) {
          border-bottom: 1px solid #7f7f7f;
        }
      }
    }
  }
}

.product__rating {
  .product--teaser & {
    @include breakpoint($bp--xlarge-up) {
      margin-bottom: 15px;
      position: absolute;
      #{$rdirection}: 0;
      text-transform: uppercase;
      top: 0;
    }
  }
}

.section-products {
  .product_header_details {
    .product__rating {
      .product__rating-non {
        width: 60px;
        text-align: right;
        line-height: 13px;
        display: block;
        margin-top: -6px;
      }
    }
  }
}

.section-employment {
  .customer-service__page {
    .customer-service__page-content {
      .basic-responsive-v1 {
        padding-right: 31%;
      }
    }
  }
}

.grid--mpp__item {
  .product--teaser.prod_inv_status-2 {
    .product__footer {
      .product__inventory-status li {
        .temp-out-of-stock__msg {
          float: right;
          padding: 6px 10px;
          background-color: #999999;
          color: #ffffff;
          font-family: $ano-bold_regular-font;
          font-size: 15px;
          cursor: default;
        }
      }
    }
  }
}

#footer_sticky_chat {
  .product-page-livechat-hitarea-mask img {
    cursor: pointer;
  }
}

.checkout .panel.need-help-panel #checkout_chat a {
  color: white;
}

.checkout .panel.need-help-panel #checkout_chat a:visited {
  color: white;
}

.checkout .panel.need-help-panel #checkout_chat a:focus {
  color: white;
}

.site-container {
  .artistry--artist {
    .artist--products__carousel-slide {
      .prod_inv_status-2 {
        .product__footer {
          .temp-out-of-stock__msg {
            display: block;
            float: #{$rdirection};
            @include swap_direction(padding, 0px 15px);
            line-height: 30px;
            height: 30px;
            background-color: $color--gray--light;
            color: $color--white;
            font-family: $ano-bold_regular-font;
            font-size: 15px;
          }
        }
      }
      .prod_inv_status-3 {
        .product__footer {
          .coming-soon-text {
            display: block;
            float: #{$rdirection};
          }
        }
      }
    }
  }
}

.site-footer {
  .site-footer--sticky {
    .mac-select-loyalty-cols {
      .mac-select-loyalty-summary {
        &__header,
        &__content {
          text-align: center;
        }
      }
    }
    .site-email-signup {
      .site-email-signup__link {
        display: none;
      }
    }
    .site-email-signup__contents-header {
      .site-email-signup__contents-close {
        display: none;
      }
    }
    .site-email-signup__contents-title {
      a {
        color: $color--white;
        font-size: 28px;
        margin-top: 0.3em;
        margin-bottom: 0.1em;
        font-family: $ano-bold_regular-font;
        font-weight: 400;
        text-transform: uppercase;
      }
    }
    .site-email-signup__contents-form {
      .field-container {
        .return-user-email-newsletter {
          width: 350px;
          height: 40px;
          float: left;
          display: inline-block;
          margin-left: 20px;
        }
        .site-email-signup__contents-submit {
          background: $color--white;
          color: $color--black;
          border-bottom: none;
          height: 45px;
          line-height: inherit;
        }
      }
    }
  }
}

.lookup_results_container {
  .address_lookup_wrapper {
    border: 1px solid $color--border;
    clear: both;
    padding: 10px;
    width: 100%;
    height: 200px;
    overflow: auto;
    margin-bottom: 15px;
    li {
      padding-bottom: 1em;
    }
  }
}

.cr21-refresh {
  .product-full {
    &--enhanced.product-full { 
      .product-full__details-container {
        div.product-full { 
          &__installment-price-wrapper {
            @include breakpoint($bp--large-up) {
              margin-top: 27px;
            }
          }
        }
      }
    }
  }
}