.site-container {
  .profile-info__fieldset {
    .picker-radio {
      float: left;
      width: auto;
    }
    .gender_col,
    .sms_col,
    .birthday_col {
      label {
        display: block;
      }
    }
    div.birthday_col {
      .select2-container {
        width: 27% !important;
        float: left;
        margin-right: 25px;
      }
    }
    .gender_col {
      margin-top: 15px;
      label {
        float: left;
      }
      .picker {
        margin: 0 1em 1em 0;
      }
    }
  }
  .newsletter-info {
    .newsletter-info {
      &__fieldset {
        .picker-label {
          width: 82%;
        }
      }
    }
  }
  .payment-info {
    .no-payment-text {
      display: none;
    }
  }
  .order-details-page {
    .cart-item {
      &__desc {
        margin-left: 18% !important;
      }
      &__price {
        margin-left: 50% !important;
        .cart-item__product-price--sale {
          display: block;
        }
      }
      &__qty {
        margin-left: 72% !important;
      }
    }
    .product-header {
      .price {
        margin-left: 50%;
      }
      .quantity {
        margin-left: 72%;
      }
    }
  }
  .account-order-history {
    .past-purchases {
      .past-purchases__item {
        .invoice-link {
          width: 20.22222%;
          float: left;
          margin-right: -100%;
          margin-left: 62.66667%;
          clear: none;
          padding-right: 1em;
        }
        .order-cost {
          width: 10.2222%;
          margin-left: 83.22222%;
        }
        .order-status {
          width: 18.22222%;
        }
      }
    }
  }
  .address-book-page__content,
  .account-order-history,
  .account-page,
  .profile-page,
  .past-purchases-page {
    padding: 0 1em;
    .account-page__header {
      .account-page__title {
        text-align: center;
        font-size: 30px;
      }
    }
  }
  .profile-page {
    .field-container {
      .form-item {
        .form-submit {
          width: 228px;
        }
      }
    }
  }
}

.js-photo-upload-overlay {
  #cboxWrapper {
    .profile-pic-upload {
      .msg-instructions {
        .btn-submit {
          clear: both;
        }
        .btn-instructions {
          #file-chosen-state {
            padding-top: 15px;
          }
        }
      }
    }
  }
}

#colorbox {
  #address_form_container {
    #address {
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }
  }
}

.sign-in-page {
  a:not(.mantle-cta--custom-textcolor):visited {
    color: $color--white;
  }
}
